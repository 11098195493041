<div class="container" id="editprices">
    <div class="row justify-content-center p-2">
        <h1>Prices crud component</h1>
    </div>
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="companyName" placeholder="Enter Shipping Line Name">
    </div>
    <div class="form-group">
        <input type="number" class="form-control" [(ngModel)]="shippingPriceTwenty"
            placeholder="Enter Price for 20 foot container">
    </div>
    <div class="form-group">
        <input type="number" class="form-control" [(ngModel)]="shippingPriceFourty"
            placeholder="Enter Price for 40 foot container">
    </div>
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="loadingPort" placeholder="Enter Loading Port">
    </div>
    <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="destinationPort" placeholder="Enter Destination Port">
    </div>
    <div class="form-group">
        <button class="btn btn-primary" (click)="CreateRecord()"
            [disabled]="!companyName || !shippingPriceTwenty || !loadingPort || !destinationPort || !shippingPriceFourty">Create
            price</button>
    </div>
    <div class="form-group">
        <h4><b>{{ message }}</b></h4>
    </div>

    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col">Port Of Loading</th>
                <th scope="col">Port Of Destination</th>
                <th scope="col">20 Foot Price</th>
                <th scope="col">40 Foot Price</th>
                <th scope="col">Company Name</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of price">
                <ng-container [ngTemplateOutlet]="item.isedit == true ? editable : display"
                    [ngTemplateOutletContext]="{ item: item }">
                </ng-container>
            </tr>
        </tbody>
    </table>


    <ng-template #display let-item="item">

        <td>{{ item.loadingPort }}</td>
        <td>{{ item.destinationPort }}</td>
        <td>{{ item.priceTwenty | currency:'USD':string:'4.0-2'}}</td>
        <td>{{ item.priceFourty | currency:'USD':string:'4.0-2'}}</td>
        <td>{{ item.company }}</td>

        <td><button (click)="EditRecord(item)" class="btn btn-primary">Edit</button></td>
        <td><button (click)="DeleteRecord(item.id)" class="btn btn-danger">Delete</button></td>
    </ng-template>
    <ng-template #editable let-item="item">
        <td class="form-group">
            <input type="text" [(ngModel)]="item.editloadingport" class="form-control">
        </td>
        <td class="form-group">
            <input type="text" [(ngModel)]="item.editDestinationPort" class="form-control">
        </td>
        <td class="form-group">
            <input type="number" [(ngModel)]="item.editPriceTwenty" class="form-control">
        </td>
        <td class="form-group">
            <input type="number" [(ngModel)]="item.editPriceFourty" class="form-control">
        </td>
        <td class="form-group">
            <input type="text" [(ngModel)]="item.editCompany" class="form-control">
        </td>

        <td><button (click)="updaterecord(item)" class="btn btn-primary">Update</button></td>
        <td><button (click)="item.isedit = false" class="btn btn-danger">Cancel</button></td>
    </ng-template>
</div>