<footer class="text-center text-lg-start bg-light text-muted" id="contacts">
  <div class="border-bottom"></div>
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            <i class="fas fa-gem me-3"></i>Transloga
          </h6>
          <p>
            Only with us clients will feel important, because cargo entrusted to our professional and experienced personnel will be handled thoroughly and quickly. There is no difference if it is a small parcel from USA, 40 feet container from China, tilt truck from Germany or train wagon being delivered to Russia.
          </p>
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">Useful links</h6>
          <p>
            <a href="https://www.omnicalculator.com/other/cbm-shipping" target="_blank" rel="noopener noreferrer" class="text-reset">Volume calculator</a>
          </p>
          <p>
            <a href="https://www.freightwaves.com/news" target="_blank" rel="noopener noreferrer" class="text-reset">Logistics news</a>
          </p>
          <p>
            <a href="http://transloga.client.4logist.com/login" target="_blank" rel="noopener noreferrer" class="text-reset">For our customers</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          <p><i class="fas fa-home me-3"></i> Svajonės st. 38, Klaipėda</p>
          <p><i class="fas fa-home me-3"></i> Lithuania, LT-94101</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            sales2@transloga.lt
          </p>
          <p><i class="fas fa-phone me-3"></i> +370 640 21244</p>

        </div>
        <form *ngIf="!isSignedIn" class="text-center border border-light col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">Admin login</h6>
          <input name="email" #email type="email" id="defaultLoginFormEmail" class="form-control mb-4"
            placeholder="E-mail" />
          <input name="password" #password type="password" id="defaultLoginFormPassword" class="form-control mb-4"
            placeholder="Password" />
          <div class="d-flex justify-content-around"></div>
          <button (click)="onSignin(email.value, password.value)" mdbBtn block="true" class="btn btn-primary"
            type="submit">
            Login
          </button>
        </form>
        <form *ngIf="isSignedIn" class="text-center border border-light col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <button (click)="logout()" mdbBtn block="true" class="btn btn-primary" type="submit">Logout</button>
        </form>
      </div>
    </div>
  </section>
  <!-- Copyright -->
  <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05)">
    © 2021 Copyright:
    <a class="text-reset fw-bold" href="#">Albertino</a>
  </div>
</footer>