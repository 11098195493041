<div class="container" id="about">
  <div class="card-group">

    <div class="shadow card text-center bg-light mr-1">
      <div class="card-body">
        <fa-icon [icon]="faShip" size="3x"></fa-icon>
        <h4 class="card-title">Ocean freight</h4>
        <p class="card-text">Our company works with both uppercase
          and lowercase shipping lines. This allows us
          offering our customers cargo transportation
          from all over the world: Asia, North and South
          America, Africa, Australia, and Europe.</p>
      </div>
    </div>
    <!-- /.card -->

    <div class="shadow card text-center bg-light ml-1 mr-1 border">
      <div class="card-body">
        <fa-icon [icon]="faTrain" size="3x"></fa-icon>
        <h4 class="card-title">Rail freight</h4>
        <p class="card-text">Our professional team will select an optimum
          route for your cargo. Various routes of cargo
          delivery include: Lithuania, Belarus, Russia,          
          Kazakhstan, and Western European countries. Leave all your worries with us and stay 
          relaxed - we will keep you informed about the
          movement of the load!</p>
      </div>
    </div>
    <!-- /.card -->

    <div class="shadow card text-center bg-light ml-1 mr-1 border">
      <div class="card-body">
        <fa-icon [icon]="faPlane" size="3x"></fa-icon>
        <h4 class="card-title">Air freight</h4>
        <p class="card-text">Whether it is a metropolis in USA, an exotic Seychelles Island or any China airport, we will provide professional solutions at a reasonable price being faster than our competitors! Sending goods has never been so easy, as it is with “Transloga”!</p>
      </div>
    </div>
    <!-- /.card -->

    <div class="shadow card text-center bg-light ml-1 border">
      <div class="card-body">
        <fa-icon [icon]="faTruck" size="3x"></fa-icon>
        <h4 class="card-title">Land freight</h4>
        <p class="card-text">Local transportation across Lithuania or an international shipment to Spain or vast distances of
          Russia? The best solution is “Transloga”!</p>
      </div>
    </div>
    <!-- /.card -->


  </div>
  <!-- /.card-group -->
</div>