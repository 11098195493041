<ngb-carousel id="home">
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="./../../assets/IMG/other/shutterstock_124765442.jpg" class="img-fluid" alt="First slide">
    </div>
    <div class="carousel-caption">
      <h3>Sea transport</h3>
      <p>We offer cargo transportation from all over the world: Asia, North and South America, Africa, Australia, and Europe.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="./../../assets/IMG/other/shutterstock_141959189.jpg" class="img-fluid" alt="Second slide">
    </div>
    <div class="carousel-caption">
      <h3>Rail transport</h3>
      <p>Various routes of cargo delivery include: Lithuania, Belarus, Russia, Kazakhstan, and Western European countries.</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="./../../assets/IMG/other/shutterstock_745545164.jpg" class="img-fluid mx-auto" alt="Third slide">
    </div>
    <div class="carousel-caption">
      <h3>Air transport</h3>
      <p>We will provide professional solutions at a reasonable price being faster than our competitors!</p>
    </div>
  </ng-template>
  <ng-template ngbSlide>
    <div class="picsum-img-wrapper">
      <img src="./../../assets/IMG/other/shutterstock_1727416391.jpg" class="img-fluid" alt="Fourth slide">
    </div>
    <div class="carousel-caption">
      <h3>Road transport</h3>
      <p>Local transportation across Lithuania or an international shipment? The best solution is “Transloga”!</p>
    </div>
  </ng-template>
</ngb-carousel>





