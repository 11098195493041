<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <div class="logo-wrapper">
      <a class="navbar-brand" href="#">
        <img src="../../assets/IMG/logo/translogalogo.png" width=auto height=45px alt="transloga logo"></a>
    </div>
    <button class="navbar-toggler" (click)="toggleNavbar()" type="button" data-toggle="collapse"
      data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [ngClass]="{ 'show': navbarOpen }" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <a class="nav-link" pageScroll href="#home">Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about">About</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" href="#pricetable">Prices</a>
        </li>-->
        <li class="nav-item">
          <a class="nav-link" href="#contacts">Contacts</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/prices" href="#editprices">Admin</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>